import {graphql, useStaticQuery} from 'gatsby'
import {Link as ScrollLink} from 'react-scroll'
import DownArrowSvg from './down-arrow-svg'
import Fade from 'react-reveal/Fade'
import React, {Component} from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import {HOME_PAGE_SCROLL_DOWN_DURATION_MS} from './../constants/global-constants'

// Documentation
// https://www.npmjs.com/package/react-slick
// https://github.com/akiran/react-slick
// https://react-slick.neostack.com/
// https://kenwheeler.github.io/slick/

export default function HeroCarousel() {
  const data = useStaticQuery(graphql`
    {
      prismicHomePage {
        data {
          hero_image_carousel {
            hero_image_desktop {
              alt
              url(imgixParams: {q: 75})
            }
            hero_image_mobile {
              alt
              url(imgixParams: {q: 75})
            }
            hero_image_tablet {
              alt
              url(imgixParams: {q: 75})
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    heroCarouselAllImages: data.prismicHomePage.data.hero_image_carousel,
  }

  const slickSlidersettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: true,
    infinite: true,
    lazyLoad: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledComponent className="hero-carousel">
      <Fade>
        <Slider {...slickSlidersettings}>
          {prismicData.heroCarouselAllImages.map((item, index) => (
            <div className="slide-wrapper" key={`slide-wrapper-${index}`}>
              <picture key={`picture-${index}`}>
                <source srcSet={item.hero_image_desktop.url} media="(min-width: 1200px)" alt={item.hero_image_desktop.alt} />
                <source srcSet={item.hero_image_tablet.url} media="(min-width: 700px)" alt={item.hero_image_tablet.alt} />
                <img src={item.hero_image_mobile.url} alt={item.hero_image_mobile.alt} />
              </picture>
            </div>
          ))}
        </Slider>
      </Fade>
      <div className="scroll-btn-wrapper">
        <Fade delay={1500}>
          <ScrollLink
            to="home-intro"
            className="scroll-btn"
            spy={true}
            smooth={true}
            offset={0}
            duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
          >
            <span className="sr-only">Scroll down</span>
            <DownArrowSvg className="down-arrow-svg" fill="#fff" />
          </ScrollLink>
        </Fade>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  z-index: 1;
  overflow: hidden;
  position: relative;

  .slide-wrapper {
    outline: 0;
    height: calc(100vh - 8.597rem - 6.6rem);
    height: calc((var(--vh, 1vh) * 100) - var(--fixed-header-height));

    @media (min-width: 375px) {
      height: calc(100vh - 9.148rem - 6.6rem);
      height: calc((var(--vh, 1vh) * 100) - var(--fixed-header-height));
    }

    @media (min-width: 1200px) {
      height: calc(100vh - 9.1rem);
      height: calc((var(--vh, 1vh) * 100) - var(--fixed-header-height));
    }

    &::after {
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      bottom: 0px;
      content: '';
      display: block;
      height: 7rem;
      pointer-events: none;
      position: absolute;
      transform: translateY(-6px);
      width: 100%;
      z-index: 1;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 50%;
    transform: translateX(-50%);
    width: 13rem;
    width: auto;

    li {
      margin: 0;
      height: 4rem;
      width: 4rem;

      button {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0;
        width: 100%;

        &::before {
          background-color: transparent;
          border-radius: 50%;
          border: 3px solid white;
          color: white;
          content: '';

          opacity: 1;
          position: static;
          height: 2.2rem;
          width: 2.2rem;
        }
      }

      &.slick-active {
        button {
          &::before {
            background-color: white;
          }
        }
      }
    }
  }

  .slick-track {
    background-color: black;
  }

  .carousel-text {
    bottom: 1rem;
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.3rem;
    margin-bottom: 0;
    position: absolute;
    right: 0.5rem;
    text-align: center;
    text-align: right;
    text-transform: uppercase;
    z-index: 2;

    /* @media (min-width: 340px) {
      font-size: 1.3rem;
    }
    @media (min-width: 355px) {
      font-size: 1.4rem;
    } */

    span {
      font-weight: 700;
    }
  }

  .scroll-btn-wrapper {
    animation: scrollDownArrowAnimation 3s linear 0s infinite normal none;
    bottom: 3.5rem;
    display: block;
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 5.5rem;
    z-index: 10;

    @media (min-width: 768px) {
      bottom: 3.5rem;
    }

    @media (min-width: 900px) {
      bottom: 3.5rem;
    }
  }
`
