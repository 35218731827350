import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'
import InstagramSvg from './instagram-svg'

export default function HomepageIntroSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicSocialMedia {
        data {
          instagram_url {
            text
          }
        }
      }
      prismicHomePage {
        data {
          ali_photo_mobile {
            alt
            url
            dimensions {
              width
              height
            }
          }
          ali_photo_tablet {
            alt
            url
            dimensions {
              width
              height
            }
          }
          ali_photo_desktop {
            alt
            url
            dimensions {
              width
              height
            }
          }
          instagram_link_text {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    mobileAliPhoto: data.prismicHomePage.data.ali_photo_mobile,
    tabletAliPhoto: data.prismicHomePage.data.ali_photo_tablet,
    desktopAliPhoto: data.prismicHomePage.data.ali_photo_desktop,
    instagramLinkText: data.prismicHomePage.data.instagram_link_text.text,
    instagramUrl: data.prismicSocialMedia.data.instagram_url.text,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <Container type="narrow">
        <div className="row">
          <Fade>
            <div>
              <hr />
              <picture className="ali-photo">
                <source media="(min-width: 900px)" srcSet={prismicData.desktopAliPhoto.url} />
                <source media="(min-width: 600px)" srcSet={prismicData.tabletAliPhoto.url} />
                <source media="(min-width: 0px)" srcSet={prismicData.mobileAliPhoto.url} />
                <img
                  src={prismicData.tabletAliPhoto.url}
                  alt={prismicData.tabletAliPhoto.alt}
                  width={prismicData.tabletAliPhoto.dimensions?.width}
                  height={prismicData.tabletAliPhoto.dimensions?.height}
                />
              </picture>

              <p className="instagram-link-wrapper">
                <a className="instagram-link" href={prismicData.instagramUrl} target="_blank">
                  <InstagramSvg fill="#666" className="instagram-svg" />
                  {prismicData.instagramLinkText}
                </a>
              </p>
            </div>
          </Fade>
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;
  position: relative;

  .home-intro-anchor {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -130px;
    z-index: 1;
  }

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .ali-photo {
    display: block;
    margin: 4rem auto;
  }

  .instagram-link-wrapper {
    text-align: center;
  }

  .instagram-svg {
    display: block;
    height: 28px;
    margin: 0 auto 1rem auto;
    width: 28px;
  }

  .instagram-link {
    color: #000000;
    display: inline-block;
  }
`
