import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import QuoteBlock from './quote-block'
import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

export default function HomepageIntroSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicHomePage {
        data {
          intro_text {
            html
          }
          testimonials {
            enabled
            quote_source {
              text
            }
            quote_text {
              html
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    introText: data.prismicHomePage.data.intro_text.html,
    testimonials: data.prismicHomePage.data.testimonials,
  }

  prismicData.testimonials = prismicData.testimonials.filter(item => item.enabled)

  const slickSlidersettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: false,
    infinite: true,
    lazyLoad: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <a id="home-intro" className="home-intro-anchor" aria-hidden="true" />
      <Container type="wide">
        <div className="row">
          {prismicData.introText && (
            <Fade>
              <div className="intro-text" dangerouslySetInnerHTML={{__html: prismicData.introText}} />
            </Fade>
          )}
          {prismicData.testimonials.length > 0 && (
            <Fade>
              <div>
                <hr />
                <div className="quotes-carousel">
                  <Slider {...slickSlidersettings}>
                    {prismicData.testimonials.map((item, index) => (
                      <QuoteBlock
                        key={index}
                        cssModifier="homepage"
                        quoteText={item.quote_text.html}
                        sourceText={item.quote_source.text}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            </Fade>
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;
  position: relative;

  .home-intro-anchor {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -130px;
    z-index: 1;
  }

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .intro-text {
    font-size: 2rem;
    margin: 4rem auto;
    max-width: 58rem;
    text-align: center;
  }

  .quotes-carousel {
    margin: 0 auto;
    max-width: 40rem;

    .slick-arrow {
      display: none !important;

      &::before {
        color: red;
      }
    }
  }
`
