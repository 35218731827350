import {graphql} from 'gatsby'
import ContactSection from './../components/contact-section'
import HeroCarousel from './../components/hero-carousel'
import HoldingPageHeroImage from './../components/holding-page-hero-image'
import HomepageFeaturedProjectsSection from './../components/homepage-featured-projects-section'
import HomepageIntroSection from './../components/homepage-intro-section'
import HomepageInstagramSection from './../components/homepage-instagram-section'
import Layout from './../components/layout'
import React, {useState} from 'react'
import {
  HOLDING_PAGE_CSS_MODIFIER,
  HOMEPAGE_INTRO_CSS_MODIFIER,
  HOMEPAGE_INSTAGRAM_CSS_MODIFIER,
  PAGE_ROOT_ELEMENT_ID,
} from './../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
    showFeaturedProjects: data.prismicHomePage.data.show_featured_projects,
  }

  return (
    <Layout
      documentTitlePrefix=""
      isIndexPage={false}
      pageName="home"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <HeroCarousel />
          <HomepageIntroSection cssModifier={HOMEPAGE_INTRO_CSS_MODIFIER} />
          {prismicData.showFeaturedProjects && <HomepageFeaturedProjectsSection />}
          <HomepageInstagramSection cssModifier={HOMEPAGE_INSTAGRAM_CSS_MODIFIER} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
    prismicHomePage {
      data {
        show_featured_projects
      }
    }
  }
`
